export const MessePage = () => import('../../components/storyblok/MessePage.vue' /* webpackChunkName: "components/messe-page" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/storyblok/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const Redirect = () => import('../../components/storyblok/Redirect.vue' /* webpackChunkName: "components/redirect" */).then(c => wrapFunctional(c.default || c))
export const RegionLandingPage = () => import('../../components/storyblok/RegionLandingPage.vue' /* webpackChunkName: "components/region-landing-page" */).then(c => wrapFunctional(c.default || c))
export const RegionPage = () => import('../../components/storyblok/RegionPage.vue' /* webpackChunkName: "components/region-page" */).then(c => wrapFunctional(c.default || c))
export const TeaserPage = () => import('../../components/storyblok/TeaserPage.vue' /* webpackChunkName: "components/teaser-page" */).then(c => wrapFunctional(c.default || c))
export const BasicButtonBlok = () => import('../../components/storyblok/basic/ButtonBlok.vue' /* webpackChunkName: "components/basic-button-blok" */).then(c => wrapFunctional(c.default || c))
export const BasicCardBlok = () => import('../../components/storyblok/basic/CardBlok.vue' /* webpackChunkName: "components/basic-card-blok" */).then(c => wrapFunctional(c.default || c))
export const BasicFile = () => import('../../components/storyblok/basic/File.vue' /* webpackChunkName: "components/basic-file" */).then(c => wrapFunctional(c.default || c))
export const BasicImage = () => import('../../components/storyblok/basic/Image.vue' /* webpackChunkName: "components/basic-image" */).then(c => wrapFunctional(c.default || c))
export const BasicLightText = () => import('../../components/storyblok/basic/LightText.vue' /* webpackChunkName: "components/basic-light-text" */).then(c => wrapFunctional(c.default || c))
export const BasicRichText = () => import('../../components/storyblok/basic/RichText.vue' /* webpackChunkName: "components/basic-rich-text" */).then(c => wrapFunctional(c.default || c))
export const BasicText = () => import('../../components/storyblok/basic/Text.vue' /* webpackChunkName: "components/basic-text" */).then(c => wrapFunctional(c.default || c))
export const BasicTimestampBlok = () => import('../../components/storyblok/basic/TimestampBlok.vue' /* webpackChunkName: "components/basic-timestamp-blok" */).then(c => wrapFunctional(c.default || c))
export const BasicVideo = () => import('../../components/storyblok/basic/Video.vue' /* webpackChunkName: "components/basic-video" */).then(c => wrapFunctional(c.default || c))
export const ContainersBackground = () => import('../../components/storyblok/containers/Background.vue' /* webpackChunkName: "components/containers-background" */).then(c => wrapFunctional(c.default || c))
export const ContainersGridContainer = () => import('../../components/storyblok/containers/GridContainer.vue' /* webpackChunkName: "components/containers-grid-container" */).then(c => wrapFunctional(c.default || c))
export const ContainersLink = () => import('../../components/storyblok/containers/Link.vue' /* webpackChunkName: "components/containers-link" */).then(c => wrapFunctional(c.default || c))
export const ContainersScript = () => import('../../components/storyblok/containers/Script.vue' /* webpackChunkName: "components/containers-script" */).then(c => wrapFunctional(c.default || c))
export const ContainersTwoCol = () => import('../../components/storyblok/containers/TwoCol.vue' /* webpackChunkName: "components/containers-two-col" */).then(c => wrapFunctional(c.default || c))
export const ContainersTwoColOverlayed = () => import('../../components/storyblok/containers/TwoColOverlayed.vue' /* webpackChunkName: "components/containers-two-col-overlayed" */).then(c => wrapFunctional(c.default || c))
export const LayoutBlock = () => import('../../components/storyblok/layout/Block.vue' /* webpackChunkName: "components/layout-block" */).then(c => wrapFunctional(c.default || c))
export const LayoutCenter = () => import('../../components/storyblok/layout/Center.vue' /* webpackChunkName: "components/layout-center" */).then(c => wrapFunctional(c.default || c))
export const LayoutContainer = () => import('../../components/storyblok/layout/Container.vue' /* webpackChunkName: "components/layout-container" */).then(c => wrapFunctional(c.default || c))
export const LayoutEqualGrid = () => import('../../components/storyblok/layout/EqualGrid.vue' /* webpackChunkName: "components/layout-equal-grid" */).then(c => wrapFunctional(c.default || c))
export const LayoutFlex = () => import('../../components/storyblok/layout/Flex.vue' /* webpackChunkName: "components/layout-flex" */).then(c => wrapFunctional(c.default || c))
export const LayoutFlexiGrid = () => import('../../components/storyblok/layout/FlexiGrid.vue' /* webpackChunkName: "components/layout-flexi-grid" */).then(c => wrapFunctional(c.default || c))
export const LayoutGrid = () => import('../../components/storyblok/layout/Grid.vue' /* webpackChunkName: "components/layout-grid" */).then(c => wrapFunctional(c.default || c))
export const LayoutSpacer = () => import('../../components/storyblok/layout/Spacer.vue' /* webpackChunkName: "components/layout-spacer" */).then(c => wrapFunctional(c.default || c))
export const LayoutViewheight = () => import('../../components/storyblok/layout/Viewheight.vue' /* webpackChunkName: "components/layout-viewheight" */).then(c => wrapFunctional(c.default || c))
export const SeoStructuredDataEvent = () => import('../../components/storyblok/seo/structuredDataEvent.js' /* webpackChunkName: "components/seo-structured-data-event" */).then(c => wrapFunctional(c.default || c))
export const TeaserArrowSection = () => import('../../components/storyblok/teaser/ArrowSection.vue' /* webpackChunkName: "components/teaser-arrow-section" */).then(c => wrapFunctional(c.default || c))
export const TeaserCenterCard = () => import('../../components/storyblok/teaser/CenterCard.vue' /* webpackChunkName: "components/teaser-center-card" */).then(c => wrapFunctional(c.default || c))
export const TeaserContactPersonSection = () => import('../../components/storyblok/teaser/ContactPersonSection.vue' /* webpackChunkName: "components/teaser-contact-person-section" */).then(c => wrapFunctional(c.default || c))
export const TeaserGridSection = () => import('../../components/storyblok/teaser/GridSection.vue' /* webpackChunkName: "components/teaser-grid-section" */).then(c => wrapFunctional(c.default || c))
export const TeaserHeaderSection = () => import('../../components/storyblok/teaser/HeaderSection.vue' /* webpackChunkName: "components/teaser-header-section" */).then(c => wrapFunctional(c.default || c))
export const TeaserTwoColSection = () => import('../../components/storyblok/teaser/TwoColSection.vue' /* webpackChunkName: "components/teaser-two-col-section" */).then(c => wrapFunctional(c.default || c))
export const ElementsArticle = () => import('../../components/storyblok/elements/Article.vue' /* webpackChunkName: "components/elements-article" */).then(c => wrapFunctional(c.default || c))
export const ElementsArticleList = () => import('../../components/storyblok/elements/ArticleList.vue' /* webpackChunkName: "components/elements-article-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsBackgroundCardList = () => import('../../components/storyblok/elements/BackgroundCardList.vue' /* webpackChunkName: "components/elements-background-card-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsBusinessExamples = () => import('../../components/storyblok/elements/BusinessExamples.vue' /* webpackChunkName: "components/elements-business-examples" */).then(c => wrapFunctional(c.default || c))
export const ElementsBusinessList = () => import('../../components/storyblok/elements/BusinessList.vue' /* webpackChunkName: "components/elements-business-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsContactForm = () => import('../../components/storyblok/elements/ContactForm.vue' /* webpackChunkName: "components/elements-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ElementsContactFormBasic = () => import('../../components/storyblok/elements/ContactFormBasic.vue' /* webpackChunkName: "components/elements-contact-form-basic" */).then(c => wrapFunctional(c.default || c))
export const ElementsCookieConsentButton = () => import('../../components/storyblok/elements/CookieConsentButton.vue' /* webpackChunkName: "components/elements-cookie-consent-button" */).then(c => wrapFunctional(c.default || c))
export const ElementsDiagonalSection = () => import('../../components/storyblok/elements/DiagonalSection.vue' /* webpackChunkName: "components/elements-diagonal-section" */).then(c => wrapFunctional(c.default || c))
export const ElementsEinBlickFeature = () => import('../../components/storyblok/elements/EinBlickFeature.vue' /* webpackChunkName: "components/elements-ein-blick-feature" */).then(c => wrapFunctional(c.default || c))
export const ElementsEinBlickFeatureTable = () => import('../../components/storyblok/elements/EinBlickFeatureTable.vue' /* webpackChunkName: "components/elements-ein-blick-feature-table" */).then(c => wrapFunctional(c.default || c))
export const ElementsEinBlickPricing = () => import('../../components/storyblok/elements/EinBlickPricing.vue' /* webpackChunkName: "components/elements-ein-blick-pricing" */).then(c => wrapFunctional(c.default || c))
export const ElementsEinBlickPricingStatic = () => import('../../components/storyblok/elements/EinBlickPricingStatic.vue' /* webpackChunkName: "components/elements-ein-blick-pricing-static" */).then(c => wrapFunctional(c.default || c))
export const ElementsEinblickCampaign = () => import('../../components/storyblok/elements/EinblickCampaign.vue' /* webpackChunkName: "components/elements-einblick-campaign" */).then(c => wrapFunctional(c.default || c))
export const ElementsFaq = () => import('../../components/storyblok/elements/Faq.vue' /* webpackChunkName: "components/elements-faq" */).then(c => wrapFunctional(c.default || c))
export const ElementsFaqElement = () => import('../../components/storyblok/elements/FaqElement.vue' /* webpackChunkName: "components/elements-faq-element" */).then(c => wrapFunctional(c.default || c))
export const ElementsFlagBanner = () => import('../../components/storyblok/elements/FlagBanner.vue' /* webpackChunkName: "components/elements-flag-banner" */).then(c => wrapFunctional(c.default || c))
export const ElementsGallery = () => import('../../components/storyblok/elements/Gallery.vue' /* webpackChunkName: "components/elements-gallery" */).then(c => wrapFunctional(c.default || c))
export const ElementsHeader = () => import('../../components/storyblok/elements/Header.vue' /* webpackChunkName: "components/elements-header" */).then(c => wrapFunctional(c.default || c))
export const ElementsJobCardExamplesSection = () => import('../../components/storyblok/elements/JobCardExamplesSection.vue' /* webpackChunkName: "components/elements-job-card-examples-section" */).then(c => wrapFunctional(c.default || c))
export const ElementsJobCarousel = () => import('../../components/storyblok/elements/JobCarousel.vue' /* webpackChunkName: "components/elements-job-carousel" */).then(c => wrapFunctional(c.default || c))
export const ElementsManualList = () => import('../../components/storyblok/elements/ManualList.vue' /* webpackChunkName: "components/elements-manual-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsMasonryGallery = () => import('../../components/storyblok/elements/MasonryGallery.vue' /* webpackChunkName: "components/elements-masonry-gallery" */).then(c => wrapFunctional(c.default || c))
export const ElementsPartnerCard = () => import('../../components/storyblok/elements/PartnerCard.vue' /* webpackChunkName: "components/elements-partner-card" */).then(c => wrapFunctional(c.default || c))
export const ElementsPassauHeader = () => import('../../components/storyblok/elements/PassauHeader.vue' /* webpackChunkName: "components/elements-passau-header" */).then(c => wrapFunctional(c.default || c))
export const ElementsPerson = () => import('../../components/storyblok/elements/Person.vue' /* webpackChunkName: "components/elements-person" */).then(c => wrapFunctional(c.default || c))
export const ElementsRegionEventList = () => import('../../components/storyblok/elements/RegionEventList.vue' /* webpackChunkName: "components/elements-region-event-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsRegistrationForm = () => import('../../components/storyblok/elements/RegistrationForm.vue' /* webpackChunkName: "components/elements-registration-form" */).then(c => wrapFunctional(c.default || c))
export const ElementsResultList = () => import('../../components/storyblok/elements/ResultList.vue' /* webpackChunkName: "components/elements-result-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsSchools = () => import('../../components/storyblok/elements/Schools.vue' /* webpackChunkName: "components/elements-schools" */).then(c => wrapFunctional(c.default || c))
export const ElementsSeoSection = () => import('../../components/storyblok/elements/SeoSection.vue' /* webpackChunkName: "components/elements-seo-section" */).then(c => wrapFunctional(c.default || c))
export const ElementsSlotForm = () => import('../../components/storyblok/elements/SlotForm.vue' /* webpackChunkName: "components/elements-slot-form" */).then(c => wrapFunctional(c.default || c))
export const ErrorMessage = () => import('../../components/global/ErrorMessage.vue' /* webpackChunkName: "components/error-message" */).then(c => wrapFunctional(c.default || c))
export const ErrorSnackbar = () => import('../../components/global/ErrorSnackbar.vue' /* webpackChunkName: "components/error-snackbar" */).then(c => wrapFunctional(c.default || c))
export const MessageHandler = () => import('../../components/global/MessageHandler.vue' /* webpackChunkName: "components/message-handler" */).then(c => wrapFunctional(c.default || c))
export const RatingSnackbar = () => import('../../components/global/RatingSnackbar.vue' /* webpackChunkName: "components/rating-snackbar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
